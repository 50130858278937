import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";

import { fbConfig } from "./config";
import Router from "./router";
import { useAuth, UserContext } from "./auth";
import { OrdersContext, useOrders } from "./db/orders";

firebase.initializeApp(fbConfig);

function App() {
  const userState = useAuth();
  const { orders, initializing } = useOrders();

  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <UserContext.Provider value={userState}>
        <OrdersContext.Provider value={{ orders, initializing }}>
          <Router />
        </OrdersContext.Provider>
      </UserContext.Provider>
    </StylesProvider>
  );
}

export default App;
