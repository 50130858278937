import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HowToRegIcon from "@material-ui/icons/HowToReg";

import styles from "./StatusFilter.module.scss";

interface StatusFilterProps {
  values: StatusFilter;
  handleChange: (status: Status) => void;
}

function StatusFilter({ values, handleChange }: StatusFilterProps) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.client_success}
            onChange={(e) => handleChange(e.target.name as Status)}
            name={"client_success"}
          />
        }
        label={
          <span className={styles.label}>
            <SyncIcon />
            In Bearbeitung (Stripe)
          </span>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.success}
            onChange={(e) => handleChange(e.target.name as Status)}
            name={"success"}
          />
        }
        label={
          <span className={styles.label}>
            <CheckCircleOutlineIcon />
            Bezahlt
          </span>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.sent}
            onChange={(e) => handleChange(e.target.name as Status)}
            name={"sent"}
          />
        }
        label={
          <span className={styles.label}>
            <MailOutlineIcon />
            Verschickt
          </span>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.ready}
            onChange={(e) => handleChange(e.target.name as Status)}
            name={"ready"}
          />
        }
        label={
          <span className={styles.label}>
            <LocationOnIcon />
            Bereit zur Abholung
          </span>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.picked_up}
            onChange={(e) => handleChange(e.target.name as Status)}
            name={"picked_up"}
          />
        }
        label={
          <span className={styles.label}>
            <HowToRegIcon />
            Abgeholt
          </span>
        }
      />
    </FormGroup>
  );
}

export default StatusFilter;
