import { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import { useAuth } from "../auth";

interface OrderState {
  orders: Order[];
  initializing: boolean;
}

export const OrdersContext = createContext<OrderState>({
  orders: [],
  initializing: true,
});

export const useOrdersContext = (): OrderState => {
  return useContext(OrdersContext);
};

interface OrdersHook {
  orders: Order[];
  initializing: boolean;
}

export function useOrders(): OrdersHook {
  const [orders, setOrders] = useState<Order[]>([]);
  const [initializing, setInitializing] = useState<boolean>(true);
  const { initializing: authInit } = useAuth();

  useEffect(() => {
    const db = firebase.firestore();

    if (authInit) return;

    return db.collection("orders").onSnapshot((snap) => {
      const _orders: Order[] = [];

      console.log("downloading orders");

      snap.forEach((doc) => {
        const order = doc.data();

        if (order) {
          _orders.push({
            id: doc.id,
            status: order.status,
            createdAt: order.createdAt,
            user: order.user,
            firstName: order.firstName,
            lastName: order.lastName,
            city: order.city,
            isShipping: order.isShipping,
          });
        }
      });

      setOrders(_orders);
      setInitializing(false);
    });
  }, [authInit]);

  return {
    orders,
    initializing,
  };
}
