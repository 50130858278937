import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { ListItemIcon, Container, Typography, Grid } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import SyncIcon from "@material-ui/icons/Sync";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ErrorIcon from "@material-ui/icons/Error";

import StatusFilter from "./components/StatusFilter";
import { useOrdersContext } from "../../db/orders";
import { DETAIL } from "../../router/routes";
import styles from "./HomeScreen.module.scss";

function HomeScreen() {
  const { orders } = useOrdersContext();
  const [filters, setFilters] = useState<StatusFilter>({
    client_success: true,
    success: true,
    sent: false,
    ready: true,
    picked_up: false,
  });

  type iGetStatusIcon = (status: string) => React.ReactElement;
  const getStatusIcon: iGetStatusIcon = (status) => {
    switch (status) {
      case "client_success":
        return <SyncIcon />;
      case "success":
        return <CheckCircleOutlineIcon />;
      case "sent":
        return <MailOutlineIcon />;
      case "ready":
        return <LocationOnIcon />;
      case "picked_up":
        return <HowToRegIcon />;
      default:
        return <ErrorIcon />;
    }
  };

  return (
    <Container className={styles.container}>
      <Typography variant={"h3"}>Bestellungen</Typography>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <List dense>
            {orders
              .filter((o) => filters[o.status])
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((o) => (
                <ListItem key={o.id}>
                  <ListItemIcon>{getStatusIcon(o.status)}</ListItemIcon>
                  <ListItemText
                    primary={`${o.firstName} ${o.lastName}`}
                    secondary={moment(o.createdAt).fromNow()}
                  />
                  <ListItemSecondaryAction>
                    <Link to={`${DETAIL}?id=${o.id}`}>Öffnen</Link>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item xs={4}>
          <StatusFilter
            values={filters}
            handleChange={(status) =>
              setFilters((prev) => {
                const newFilters = Object.assign({}, prev);
                newFilters[status] = !prev[status];
                return newFilters;
              })
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomeScreen;
