import React from "react";
import {
  Avatar,
  Divider,
  List,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import frames from "../../../../config/frames";
import ListItem from "@material-ui/core/ListItem";
import styles from "../../DetailScreen.module.scss";

interface ItemsListProps {
  items: Item[];
}

function ItemsList({ items }: ItemsListProps) {
  return (
    <div>
      <Typography variant={"h5"}>Items</Typography>
      <List dense>
        {items.map((item) => {
          const frame = frames.find((f) => f.id === item.frame);
          const dim = frame!.dims.find((d) => d.id === item.dim);
          const layout = dim!.layouts.find((l) => l.id === item.layout);

          if (frame && dim && layout) {
            return (
              <div key={item.id}>
                <ListItem>
                  <a href={item.img} download>
                    <ListItemAvatar className={styles.avatarContainer}>
                      <Avatar
                        src={item.img}
                        variant={"square"}
                        className={styles.avatar}
                      />
                    </ListItemAvatar>
                  </a>
                  <ListItemText>
                    <div>Frame: {frame.name}</div>
                    <div>Size: {dim.name}</div>
                    <div>Layout: {layout.name}</div>
                    <div>Color: {item.color}</div>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {item.price.toLocaleString(undefined, {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </div>
            );
          }

          throw Error(
            `Frame ${item.id}, Dim ${item.dim} or Layout ${item.layout} not found`
          );
        })}
      </List>
    </div>
  );
}

export default ItemsList;
