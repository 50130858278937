import React, { useState } from "react";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

import styles from "./ContactSection.module.scss";

interface ContactSectionProps {
  order: OrderDetail;
  updateStatus(status: string): void;
}

function ContactSection({ order, updateStatus }: ContactSectionProps) {
  const {
    isShipping,
    firstName,
    lastName,
    email,
    address,
    zipCode,
    city,
  } = order;

  const [status, setStatus] = useState<string>(order.status);

  return (
    <div>
      <Typography variant={"h5"} gutterBottom>
        Contact
      </Typography>
      <section className={styles.section}>
        <FormControl className={styles.statusSelect}>
          <InputLabel id={"status-select-label"}>Status</InputLabel>
          <Select
            labelId={"status-select-label"}
            value={status}
            onChange={(event) => setStatus(event.target.value as string)}
          >
            <MenuItem
              value={"client_success"}
              disabled={order.status !== "client_success"}
            >
              Bezahlt (unbestätigt)
            </MenuItem>
            <MenuItem
              value={"success"}
              disabled={order.status === "client_success"}
            >
              Bezahlt
            </MenuItem>
            <MenuItem
              value={"sent"}
              disabled={order.status === "client_success" || !isShipping}
            >
              Versendet
            </MenuItem>
            <MenuItem
              value={"ready"}
              disabled={order.status === "client_success" || isShipping}
            >
              Bereit zur Abholung
            </MenuItem>
            <MenuItem
              value={"picked_up"}
              disabled={order.status === "client_success" || isShipping}
            >
              Abgeholt
            </MenuItem>
          </Select>
        </FormControl>
      </section>
      <section className={styles.section}>
        <div>Zustellung: {isShipping ? "Versand" : "Abholung"}</div>
        <div>
          {firstName} {lastName}
        </div>
        <div>{email}</div>
        {isShipping && (
          <>
            <div>{address}</div>
            <div>
              {zipCode}, {city}
            </div>
          </>
        )}
      </section>
      <section className={styles.section}>
        <Button
          disabled={status === order.status}
          onClick={() => updateStatus(status)}
        >
          Speichern
        </Button>
      </section>
    </div>
  );
}

export default ContactSection;
