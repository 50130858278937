export default [
  {
    id: 0,
    name: "Test Produkt",
    desc: "This is a fake product to test the stripe integration",
    dims: [
      {
        id: 1,
        name: "Test dimension",
        width: 10,
        height: 10,
        borderLeft: 1,
        borderTop: 1,
        price: 0.5,
        layouts: [
          {
            id: 1,
            name: "Test Layout",
            width: 10,
            height: 10,
          },
        ],
      },
    ],
    colors: [{ id: "light" }],
    colorDefault: "light",
  },
  {
    id: 1,
    name: "Gallery Frame",
    desc:
      "This is a description of the gallery frame. \n Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, repellendus.",
    dims: [
      {
        id: 1,
        name: "30cm x 30cm",
        width: 30,
        height: 30,
        borderTop: 2,
        borderLeft: 2,
        price: 45,
        layouts: [
          {
            id: 1,
            name: "10cm x 10cm",
            width: 10,
            height: 10,
          },
          {
            id: 2,
            name: "10cm x 20cm",
            width: 10,
            height: 20,
          },
          {
            id: 3,
            name: "Full Bleed",
            width: 26,
            height: 26,
          },
        ],
      },
      {
        id: 2,
        name: "40cm x 40cm",
        width: 40,
        height: 40,
        borderTop: 2,
        borderLeft: 2,
        price: 60,
        layouts: [
          {
            id: 1,
            name: "5cm x 10cm",
            width: 5,
            height: 10,
          },
          {
            id: 2,
            name: "10cm x 20cm",
            width: 10,
            height: 20,
          },
          {
            id: 3,
            name: "Full Bleed",
            width: 36,
            height: 36,
          },
        ],
      },
      {
        id: 3,
        name: "80cm x 40cm",
        width: 80,
        height: 40,
        borderTop: 2,
        borderLeft: 2,
        price: 100,
        layouts: [
          {
            id: 1,
            name: "Full Bleed",
            width: 76,
            height: 36,
          },
        ],
      },
    ],
    colors: [
      { id: "maple" },
      { id: "walnut", costMod: 12 },
      { id: "black", costMod: -5 },
    ],
    colorDefault: "walnut",
  },
  {
    id: 2,
    name: "Floating Frame",
    desc:
      "This is a description of the floating frame. \n Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, repellendus.",
    dims: [
      {
        id: 1,
        name: "20cm x 20cm",
        width: 20,
        height: 20,
        borderTop: 3,
        borderLeft: 3,
        price: 30,
        layouts: [
          {
            id: 1,
            name: "10cm x 10cm",
            width: 10,
            height: 10,
          },
          {
            id: 2,
            name: "Full Bleed",
            width: 14,
            height: 14,
          },
        ],
      },
      {
        id: 2,
        name: "40cm x 40cm",
        width: 40,
        height: 40,
        borderTop: 3,
        borderLeft: 3,
        price: 50,
        layouts: [
          {
            id: 1,
            name: "30cm x 30cm",
            width: 30,
            height: 30,
          },
          {
            id: 2,
            name: "Full Bleed",
            width: 34,
            height: 34,
          },
        ],
      },
    ],
    colors: [{ id: "white" }, { id: "black" }],
    colorDefault: "white",
  },
  {
    id: 3,
    name: "Prints",
    desc:
      "This is a description of the floating frame. \n Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, repellendus.",
    noFrame: true,
    dims: [
      {
        id: 1,
        name: "15cm x 10cm",
        width: 15,
        height: 10,
        price: 0.75,
        layouts: [
          {
            id: 1,
            name: "Full Bleed",
            width: 15,
            height: 10,
          },
        ],
      },
    ],
    colors: [
      {
        id: "none",
      },
    ],
    colorDefault: "none",
  },
];
