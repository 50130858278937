import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import LoginScreen from "../screens/LoginScreen";

import * as ROUTES from "./routes";
import { useUser } from "../auth";
import HomeScreen from "../screens/HomeScreen";
import DetailScreen from "../screens/DetailScreen";

function Router() {
  const { user, initializing } = useUser();

  let home;
  if (initializing) {
    home = <div>Initializing</div>;
  } else if (user) {
    home = <HomeScreen />;
  } else {
    home = <Redirect to={ROUTES.LOGIN} />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={ROUTES.LOGIN}>
          <LoginScreen />
        </Route>
        <Route path={ROUTES.DETAIL}>
          <DetailScreen />
        </Route>
        <Route path={ROUTES.HOME}>{home}</Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
