import React from "react";
import { useLocation, Link, Redirect } from "react-router-dom";

import DetailScreen from "./DetailScreen";
import { useOrdersContext } from "../../db/orders";
import { HOME } from "../../router/routes";

function DetailScreenWrapper() {
  const location = useLocation();
  const { orders, initializing } = useOrdersContext();

  const query = new URLSearchParams(location.search);
  const orderId = query.get("id");

  if (!orderId) {
    return <Redirect to={HOME} />;
  }

  const order = orders.find((o) => o.id === orderId);

  if (initializing) {
    return <div>Loading Order with id {orderId}</div>;
  }

  if (order) {
    return <DetailScreen order={order} />;
  }

  return (
    <div>
      Order with id {orderId} not found <br /> <Link to={HOME}>Zurück</Link>
    </div>
  );
}

export default DetailScreenWrapper;
