import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import firebase from "firebase/app";

import { HOME } from "../../router/routes";
import styles from "./DetailScreen.module.scss";
import ContactSection from "./components/ContactSection";
import ItemsList from "./components/ItemsList";

interface DetailScreenProps {
  order: Order;
}

function DetailScreen({ order }: DetailScreenProps) {
  const { user, id } = order;
  const history = useHistory();
  const [orderData, setOrderData] = useState<OrderDetail>();
  const [items, setItems] = useState<Item[]>([]);

  function updateStatus(status: string): void {
    firebase
      .firestore()
      .doc(`users/${user}/orders/${id}`)
      .update({
        status,
      })
      .then(() => {
        history.push(HOME);
      });
  }

  useEffect(() => {
    const db = firebase.firestore();
    const orderRef = db
      .collection("users")
      .doc(user)
      .collection("orders")
      .doc(id);

    orderRef.get().then((doc) => {
      const data = doc.data();
      if (data) {
        setOrderData(data as OrderDetail);
      }
    });

    orderRef
      .collection("items")
      .get()
      .then((querySnap) => {
        const _items: Item[] = [];
        const promises: Promise<any>[] = [];
        querySnap.forEach((doc) => {
          const item = doc.data();

          promises.push(
            firebase
              .storage()
              .ref(item.path)
              .getDownloadURL()
              .then((url) => {
                _items.push(
                  Object.assign(item as Item, { id: doc.id, img: url })
                );
              })
          );
        });
        Promise.all(promises).then(() => {
          setItems(_items);
        });
      });
  }, [user, id]);

  if (!orderData) {
    return <div>Loading Order Data</div>;
  }

  return (
    <Container>
      <header className={styles.header}>
        <Link to={HOME}>
          <Button startIcon={<ArrowBackIcon />}>Übersicht</Button>
        </Link>
      </header>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <ItemsList items={items} />
        </Grid>
        <Grid item xs={4}>
          <ContactSection order={orderData} updateStatus={updateStatus} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default DetailScreen;
