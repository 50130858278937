import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Container,
  Paper,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@material-ui/core";
import firebase from "firebase/app";

import styles from "./LoginScreen.module.scss";
import { HOME } from "../../router/routes";
import { getUrl } from "../../utils";

function LoginScreen() {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  const [linkSent, setLinkSent] = useState<boolean>(false);

  const { isValid } = formState;

  const onSubmit: (data: { email?: string }) => void = (data) => {
    console.log(data);

    if (data.email) {
      firebase
        .auth()
        .sendSignInLinkToEmail(data.email, {
          url: getUrl() + HOME,
          handleCodeInApp: true,
        })
        .then(() => {
          setLinkSent(true);
          window.localStorage.setItem("emailForLogin", data.email!);
        })
        .catch((err) => {
          console.log(err);
          throw Error(`Login Problem (${err.code})`);
        });
    }
  };

  return (
    <Container className={styles.screen}>
      <Paper className={styles.container}>
        <h1 className={styles.heading}>Login</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor={"email"}>Email</InputLabel>
            <Input
              id={"email"}
              inputProps={{
                name: "email",
                ref: register({
                  required: "Email Adresse wird benötigt",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Ungültige Email Adresse",
                  },
                }),
              }}
            />
          </FormControl>
          <Button
            className={styles.button}
            type={"submit"}
            color={"primary"}
            variant={"contained"}
            disabled={!isValid}
          >
            Login-Link schicken
          </Button>
        </form>
        {linkSent && (
          <p className={styles.info}>
            Eine Email mit Login Link wurde an Sie verschickt.
          </p>
        )}
      </Paper>
    </Container>
  );
}

export default LoginScreen;
