import { createContext, useEffect, useState, useContext } from "react";
import firebase from "firebase/app";

type UserOrNull = firebase.User | null;
interface UserState {
  user: UserOrNull;
  initializing: boolean;
}

export const UserContext = createContext<UserState>({
  user: null,
  initializing: true,
});

export const useUser = (): UserState => {
  return useContext(UserContext);
};

interface AuthHook {
  user: UserOrNull;
  initializing: boolean;
}

export function useAuth(): AuthHook {
  const [initializing, setInitializing] = useState<boolean>(true);
  const [user, setUser] = useState(firebase.auth().currentUser);

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem("emailForLogin");

      if (!email) {
        email = window.prompt("Bitte Email Adresse bestätigen");
      }

      if (email) {
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem("emailForLogin");
            console.log("result");
            console.log(result);
            setUser(result.user);
            setInitializing(false);
          })
          .catch((err) => {
            throw Error(`Authentication Problem ${err.code}`);
          });
      } else {
        setInitializing(false);
      }
    } else {
      return firebase.auth().onAuthStateChanged((currentUser) => {
        if (currentUser) {
          setUser(currentUser);
        }
        setInitializing(false);
      });
    }
  }, []);

  return {
    user,
    initializing,
  };
}
